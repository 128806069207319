import axios from "axios";
import { resources } from "./resources";

export const api = axios.create(
    {
        baseURL: 'https://seashell-app-dscr5.ondigitalocean.app/api',
        headers: { 
            'Content-Type': 'application/json',
        }
    }
)

export const setAuthorizationToken = (token) => {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export const revokeAUthorization = async () => {
    try {
        const { data } = await api.get(resources.logoutUrl);
        return data
    } catch (error) {
        console.log(error)
    }
}